import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { endpoints } from '../endpoints';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { PaymentType } from '../../../types/payment-options';
import { mockResponses } from '../mockObjects';
import { EnvironmentType } from '../../../types/environment-type';
import {
  PaymentIntentParams,
  SetupIntentResponse,
  TransactionResponse,
  UsAchPaymentType,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private readonly baseUrl: string;
  private readonly env: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configService: ConfigurationService,
  ) {
    this.baseUrl = this.configService.getVAMMBaseUrl();
    this.env = this.configService.getEnv();
  }

  // Create a setup intent
  async createSetupIntent(paymentType: PaymentType): Promise<SetupIntentResponse> {
    if (this.env === EnvironmentType.MOCK) {
      return mockResponses.PAYMENTS.createSetupIntent;
    }
    return await firstValueFrom(
      this.http
        .post<SetupIntentResponse>(
          `${this.baseUrl}${endpoints.PAYMENTS.CREATE_SETUP_INTENT(paymentType)}`,
          null,
          {
            headers: this.authenticationService.httpHeaders(),
            withCredentials: true,
          },
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error in CreateSetupIntent:', error);
            return throwError(() => error);
          }),
        ),
    );
  }

  // Create a new payment intent for a given paymentMethod
  async createPaymentIntent(
    clientId: string,
    paymentType: PaymentType,
    body: PaymentIntentParams,
  ): Promise<TransactionResponse> {
    if (this.env === EnvironmentType.MOCK) {
      return mockResponses.PAYMENTS.createPaymentIntent;
    }
    return await firstValueFrom(
      this.http
        .post<TransactionResponse>(
          `${this.baseUrl}${endpoints.PAYMENTS.CREATE_PAYMENT_INTENT(clientId, paymentType)}`,
          body,
          {
            headers: this.authenticationService.httpHeaders(),
            withCredentials: true,
          },
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error in CreatePaymentIntent:', error);
            return throwError(() => error);
          }),
        ),
    );
  }

  // Get all payment methods for a given payment type
  async getPaymentMethods(paymentType: PaymentType): Promise<UsAchPaymentType[]> {
    if (this.env === EnvironmentType.MOCK) {
      return mockResponses.PAYMENTS.getPaymentMethods;
    }
    return await firstValueFrom(
      this.http
        .get<UsAchPaymentType[]>(
          `${this.baseUrl}${endpoints.PAYMENTS.GET_PAYMENT_METHODS(paymentType)}`,
          {
            headers: this.authenticationService.httpHeaders(),
            withCredentials: true,
          },
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error in GetPaymentMethods:', error);
            return throwError(() => error);
          }),
        ),
    );
  }

  // Delete a payment method by id
  async deletePaymentMethod(paymentType: PaymentType, paymentMethodId: string): Promise<null> {
    return await firstValueFrom(
      this.http
        .delete<null>(
          `${this.baseUrl}${endpoints.PAYMENTS.DELETE_PAYMENT_METHOD(
            paymentType,
            paymentMethodId,
          )}`,
          {
            headers: this.authenticationService.httpHeaders(),
            withCredentials: true,
          },
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error in DeletePaymentMethods:', error);
            return throwError(() => error);
          }),
        ),
    );
  }
}
