<app-modal
  [showModal]="(consentStatus$ | async) === 'pending' ? true : false"
  [width]="800"
  (emitClose)="declineAndCloseConsentModal()"
  id="consent-modal"
>
  <div class="pvd-modal__heading-wrapper" id="consent-modal-heading">
    <h2 slot="heading" class="pvd-modal__heading">Disclosure</h2>
  </div>
  <div class="pvd-modal__body-wrapper" id="consent-modal-body-wrapper">
    <p style="margin-bottom: 24px"
      >To pay the company for your stock award (including applicable taxes) through the application,
      you are required to provide Fidelity with information about your bank account. You represent
      and warrant to Fidelity that such information is true and that you are authorized to use that
      account as an applicable payment method. Fidelity uses Stripe, Inc. as its third-party payment
      processor to process your payment for your stock award and you agree to share your personal
      data, bank account information and other data relating to this transaction with Stripe. You
      understand that Fidelity won’t be responsible for any loss, liability, cost, or expense for
      acting upon your payment instructions, including bank fees that may be charged to you, or for
      errors made by the company or your bank.
    </p>
  </div>
  <div
    style="
      margin-top: 8px;
      padding: 16px;
      border-top: #d3d3d3 1px solid;
      display: flex;
      justify-content: center;
    "
  >
    <button
      class="pvd-button-root pvd-button--tertiary"
      type="button"
      style="margin-right: 8px"
      id="consent-modal-decline-button"
      (click)="declineAndCloseConsentModal()"
    >
      <div class="pvd-button__contents">Decline</div>
    </button>
    <button
      class="pvd-button-root pvd-button--with-icon pvd-button--primary"
      type="button"
      (click)="acknowledgeAndCloseConsentModal()"
      id="consent-modal-accept-button"
    >
      <div class="pvd-button__contents">
        <span class="pvd-button__text">Accept Terms</span>
      </div>
    </button>
  </div>
  <div style="float: right; font-size: small"
    ><p>{{ eReviewVersionNumber }}</p></div
  >
</app-modal>

<div
  *ngIf="(consentStatus$ | async) === 'accepted' && participantExists; else widgetRendering"
  style="padding: 16px 0px"
>
  <app-payment-method
    [paymentMethod]="paymentOptions.paymentType"
    [returnUrl]="returnUrl"
    [hidden]="paymentSubmitInProgress"
  />
  <app-spinner
    [showSpinner]="paymentSubmitInProgress"
    [spinnerMessageOverride]="paymentSubmitMsgOverride"
  />
  <div class="pvd-microcopy-root" id="send-payment-consent-microcopy">
    <p class="pvd-microcopy__microcopy">
      By clicking Submit, you authorize the bank account specified above to be debited for any
      amount owed to the company in connection with your stock award, pursuant to the company’s
      applicable documentation, until this authorization is revoked. You may amend or cancel this
      authorization at any time by providing thirty days notice to your Company.
    </p>
  </div>
  <app-alert
    [showAlert]="(sendPaymentAlert$ | async)!"
    [errorResponse]="sendPaymentErrorResponse"
    [alertMessageOverride]="sendPaymentAlertOverride"
    [type]="alertType"
  ></app-alert>
  <div style="margin-top: 24px; padding: 0px 16px; display: flex; justify-content: flex-end">
    <button
      class="pvd-button-root pvd-button--with-icon pvd-button--primary"
      type="button"
      id="payment-method-continue-button"
      [disabled]="!(paymentMethodForSubmission$ | async) || paymentSubmitInProgress"
      (click)="onSubmit()"
    >
      <div class="pvd-button__contents">
        <span class="pvd-button__text">Submit</span>
      </div>
    </button>
  </div>
  <div style="margin-top: 8px; display: flex; justify-content: flex-end; font-size: small"
    ><p>{{ eReviewVersionNumber }}</p></div
  >
</div>
<ng-template #widgetRendering>
  <div id="pre-widget-render">
    <app-spinner
      [showSpinner]="participantLookupInProgress"
      [spinnerMessageOverride]="participantLookupMsgOverride"
    ></app-spinner>
    <app-alert
      [showAlert]="widgetRenderAlert || (consentStatus$ | async) === 'declined'"
      [errorResponse]="widgetRenderErrorResponse"
      [alertMessageOverride]="widgetRenderAlertOverride"
      [type]="alertType"
    ></app-alert>
  </div>
</ng-template>
