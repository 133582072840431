import { Component, Input, OnInit } from '@angular/core';
import { PaymentEventService, PaymentsService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertType } from '../../../types/alert-type';
import { PaymentType } from '../../../types/payment-options';
import { Observable } from 'rxjs';
import { UsAchPaymentType } from '../../services/types';

@Component({
  selector: 'app-list-payment-methods',
  templateUrl: './list-payment-methods.component.html',
  styleUrls: ['./list-payment-methods.component.scss'],
})
export class ListPaymentMethodsComponent implements OnInit {
  @Input() paymentMethod: PaymentType;
  availablePaymentMethods$!: Observable<UsAchPaymentType[]>;
  paymentMethodsLoading = false;
  removalInProgress = false;
  displayPaymentMethodRemovalModal = false;
  removePaymentSpinnerMsgOverride = 'Removing...';

  listPaymentMethodError = false;
  listPaymentMethodErrorResponse = new HttpErrorResponse({});
  listPaymentAlertType = AlertType.CRITICAL;
  removePaymentMethodError = false;
  removePaymentMethodErrorResponse = new HttpErrorResponse({});
  removePaymentAlertType = AlertType.CRITICAL;

  constructor(
    private paymentsService: PaymentsService,
    private paymentEventService: PaymentEventService,
  ) {}

  ngOnInit() {
    this.availablePaymentMethods$ = this.paymentEventService.getPaymentMethods();
    this.retrievePaymentMethods();
  }

  propagateRadioSelection(paymentMethodId: string): void {
    this.paymentEventService.setPaymentMethodForSubmission(paymentMethodId);
  }

  displayRemovalModal(paymentMethodId: string): void {
    this.displayPaymentMethodRemovalModal = true;
    this.paymentEventService.setPaymentMethodForRemoval(paymentMethodId);
  }

  closeRemovalModal(): void {
    this.paymentEventService.setPaymentMethodForRemoval(null);
    this.displayPaymentMethodRemovalModal = false;
    this.removePaymentMethodError = false;
  }

  removePaymentMethod() {
    this.removalInProgress = true;
    this.paymentsService
      .deletePaymentMethod(
        this.paymentMethod,
        this.paymentEventService.getPaymentMethodForRemoval()!,
      )
      .then(() => {
        this.removalInProgress = false;
        this.closeRemovalModal();
        this.retrievePaymentMethods();
      })
      .catch((error) => {
        this.removePaymentMethodError = true;
        this.removePaymentMethodErrorResponse = error as HttpErrorResponse;
        this.removalInProgress = false;
      });
  }

  retrievePaymentMethods(): void {
    this.paymentMethodsLoading = true;
    this.paymentsService
      .getPaymentMethods(this.paymentMethod)
      .then((response: UsAchPaymentType[]) => {
        this.paymentMethodsLoading = false;
        this.paymentEventService.setPaymentMethods(response);
        this.paymentEventService.setPaymentMethodForSubmission(null);
        this.paymentEventService.setPaymentSubmissionAlert(false);
      })
      .catch((error) => {
        this.paymentMethodsLoading = false;
        this.listPaymentMethodError = true;
        this.listPaymentMethodErrorResponse = error;
      });
  }
}
