import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AccountsService,
  PaymentEventService,
  PaymentsService,
  UserConsentService,
} from '../services';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertType } from '../../types/alert-type';
import { PaymentOptions, PaymentType } from '../../types/payment-options';
import { ConsentStatus } from '../../types/consent-status';
import {
  CONSENT_DECLINED,
  EREVIEW_VERSION,
  PART_LOOKUP_ERROR,
  PAYMENT_SUBMIT_ERROR,
  PAYMENT_SUBMIT_SUCCESS,
} from '../../globals';
import { Observable } from 'rxjs';
import { ParticipantResponse, PaymentIntentParams, TransactionResponse } from '../services/types';

@Component({
  selector: 'app-send-payment',
  templateUrl: './send-payment.component.html',
  styleUrls: ['./send-payment.component.scss'],
})
export class SendPaymentComponent implements OnInit {
  @Input() returnUrl: string;
  @Input() clientId: string;
  @Input() paymentOptions: PaymentOptions<PaymentType>;
  @Input() metadata?: string;
  @Output() submitSuccess = new EventEmitter<string>();
  @Output() submitFailure = new EventEmitter<void>();
  @Output() declineConsent = new EventEmitter<void>();

  eReviewVersionNumber = EREVIEW_VERSION;
  paymentSubmitInProgress = false;
  sendPaymentAlert$: Observable<boolean>;
  sendPaymentErrorResponse = new HttpErrorResponse({});
  sendPaymentAlertOverride = '';
  participantExists = false;
  participantLookupInProgress = false;
  participantLookupMsgOverride = 'Looking up participant...';
  widgetRenderErrorResponse = new HttpErrorResponse({});
  widgetRenderAlertOverride = '';
  widgetRenderAlert = false;
  paymentSubmitMsgOverride = 'Submitting...';
  paymentMethodForSubmission$: Observable<string | null>;
  consentStatus$: Observable<ConsentStatus>;

  alertType: AlertType;
  userConsentService: UserConsentService;
  paymentEventService: PaymentEventService;

  constructor(
    userConsentService: UserConsentService,
    paymentEventService: PaymentEventService,
    private accountsService: AccountsService,
    private paymentsService: PaymentsService,
  ) {
    this.userConsentService = userConsentService;
    this.paymentEventService = paymentEventService;
  }

  ngOnInit() {
    this.paymentMethodForSubmission$ = this.paymentEventService.getPaymentMethodForSubmission();
    this.sendPaymentAlert$ = this.paymentEventService.getPaymentSubmissionAlert();
    this.consentStatus$ = this.userConsentService.getConsentStatus();

    const consentState = sessionStorage.getItem('vammPayerConsent');
    if (consentState && consentState === ConsentStatus.ACCEPTED) {
      this.userConsentService.acknowledgeConsent();
      this.lookupParticipant();
    }
  }

  acknowledgeAndCloseConsentModal() {
    this.userConsentService.acknowledgeConsent();
    sessionStorage.setItem('vammPayerConsent', ConsentStatus.ACCEPTED);
    this.lookupParticipant();
  }

  declineAndCloseConsentModal() {
    this.userConsentService.declineConsent();
    this.widgetRenderAlertOverride = CONSENT_DECLINED;
    this.declineConsent.emit();
  }

  resetConsentModal() {
    this.userConsentService.resetConsent();
  }

  lookupParticipant() {
    this.participantLookupInProgress = true;
    this.accountsService
      .createParticipant(this.paymentOptions.paymentType)
      .then((participant: ParticipantResponse) => {
        this.paymentEventService.setUserBillingDetails(participant);
        this.participantLookupInProgress = false;
        this.participantExists = true;
      })
      .catch((error) => {
        this.participantLookupInProgress = false;
        this.widgetRenderAlert = true;
        this.widgetRenderErrorResponse = error as HttpErrorResponse;
        this.widgetRenderAlertOverride = PART_LOOKUP_ERROR;
      });
  }

  onSubmit() {
    //reset alert and spinner
    this.paymentEventService.setPaymentSubmissionAlert(false);
    this.paymentSubmitInProgress = true;
    let paymentIntent!: PaymentIntentParams;

    //construct request body and validate
    try {
      paymentIntent = {
        amount: this.paymentOptions.amount,
        currency: this.paymentOptions.currency.toLowerCase(),
        payment_method_id: this.paymentEventService.paymentMethodForSubmissionSubject.getValue()!,
        metadata: this.metadata,
      };
      this.validatePaymentIntent(paymentIntent);
    } catch (error) {
      this.handlePaymentSubmissionAlert(AlertType.CRITICAL, error as Error);
      this.submitFailure.emit();
      return;
    }

    //submit payment intent
    this.paymentsService
      .createPaymentIntent(this.clientId, this.paymentOptions.paymentType, paymentIntent)
      .then((transaction: TransactionResponse) => {
        this.handlePaymentSubmissionAlert(AlertType.SUCCESS);
        this.submitSuccess.emit(transaction.transactionId);
      })
      .catch((error) => {
        this.handlePaymentSubmissionAlert(AlertType.CRITICAL, error);
        this.submitFailure.emit();
      });
  }

  //alert component expects HttpErrorResponse shape, however alertMessageOverride bypasses this.  The below will work since we bypass with override,
  //but a correct approach would require us to accept multiple Error shapes, not just HttpErrorResponse.
  //As is it will break if we were not supplying the alertMessageOverride.
  validatePaymentIntent(paymentIntent: PaymentIntentParams): void {
    if (!paymentIntent.payment_method_id || !paymentIntent.currency || !paymentIntent.amount) {
      throw new Error('Invalid payment intent.');
    }
    if (paymentIntent.amount < 0 || paymentIntent.amount > 99999999) {
      throw new Error('Invalid payment amount.');
    }
    if (typeof paymentIntent.metadata !== 'string' && paymentIntent.metadata !== undefined) {
      throw new Error('Metadata must be a string or undefined.');
    }
    if (!paymentIntent.payment_method_id.startsWith('pm_')) {
      throw new Error('Invalid payment method id.');
    }
  }

  handlePaymentSubmissionAlert(alertType: AlertType, error?: Error | HttpErrorResponse) {
    this.paymentSubmitInProgress = false;
    this.paymentEventService.setPaymentSubmissionAlert(true);
    this.sendPaymentErrorResponse = error as HttpErrorResponse;
    this.sendPaymentAlertOverride =
      alertType === AlertType.SUCCESS ? PAYMENT_SUBMIT_SUCCESS : PAYMENT_SUBMIT_ERROR;
    this.alertType = alertType;
  }
}
