import { Component, Input, OnInit } from '@angular/core';
import { PaymentType } from '../../types/payment-options';
import { Observable } from 'rxjs';
import { PaymentEventService } from '../services';
import { UsAchPaymentType } from '../services/types';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @Input() paymentMethod: PaymentType;
  @Input() returnUrl: string;
  paymentMethods$!: Observable<UsAchPaymentType[]>;

  constructor(private paymentEventService: PaymentEventService) {}

  ngOnInit() {
    this.paymentMethods$ = this.paymentEventService.getPaymentMethods();
  }
}
