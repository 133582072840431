import { Injectable } from '@angular/core';
import { ConsentStatus } from '../../../types/consent-status';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserConsentService {
  private consentStatusSubject = new BehaviorSubject<ConsentStatus>(ConsentStatus.PENDING);
  private consentStatusObservable$ = this.consentStatusSubject.asObservable();

  acknowledgeConsent(): void {
    this.consentStatusSubject.next(ConsentStatus.ACCEPTED);
  }

  declineConsent(): void {
    this.consentStatusSubject.next(ConsentStatus.DECLINED);
  }

  getConsentStatus(): Observable<ConsentStatus> {
    return this.consentStatusObservable$;
  }

  resetConsent(): void {
    this.consentStatusSubject.next(ConsentStatus.PENDING);
  }
}
