import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParticipantResponse, UsAchPaymentType } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PaymentEventService {
  paymentMethodForSubmissionSubject = new BehaviorSubject<string | null>(null);
  private paymentMethodForSubmissionObservable$ =
    this.paymentMethodForSubmissionSubject.asObservable();
  private paymentSubmissionAlertSubject = new BehaviorSubject<boolean>(false);
  private paymentSubmissionAlertObservable$ = this.paymentSubmissionAlertSubject.asObservable();
  private paymentMethodForRemoval: string | null;
  private paymentMethodsSubject = new BehaviorSubject<UsAchPaymentType[]>([]);
  private paymentMethodsObservable$ = this.paymentMethodsSubject.asObservable();
  private userBillingDetails: ParticipantResponse;

  setPaymentMethodForSubmission(paymentMethodId: string | null): void {
    this.paymentMethodForSubmissionSubject.next(paymentMethodId);
  }

  getPaymentMethodForSubmission(): Observable<string | null> {
    return this.paymentMethodForSubmissionObservable$;
  }

  setPaymentMethodForRemoval(paymentMethodId: string | null): void {
    this.paymentMethodForRemoval = paymentMethodId;
  }

  getPaymentMethodForRemoval(): string | null {
    return this.paymentMethodForRemoval;
  }

  setPaymentMethods(paymentMethods: UsAchPaymentType[]): void {
    this.paymentMethodsSubject.next(paymentMethods);
  }

  getPaymentMethods(): Observable<UsAchPaymentType[]> {
    return this.paymentMethodsObservable$;
  }

  setUserBillingDetails(userBillingDetails: ParticipantResponse) {
    this.userBillingDetails = userBillingDetails;
  }

  getUserBillingDetails(): ParticipantResponse {
    return this.userBillingDetails;
  }

  setPaymentSubmissionAlert(showAlert: boolean): void {
    this.paymentSubmissionAlertSubject.next(showAlert);
  }

  getPaymentSubmissionAlert(): Observable<boolean> {
    return this.paymentSubmissionAlertObservable$;
  }
}
